import axios, { HttpStatusCode } from 'axios';

const httpClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json; charset=UTF-8',
	},
	validateStatus: () => true,
});

httpClient.interceptors.request.use((request) => {
	if (request.data instanceof FormData) request.headers.setContentType('');
	if (request.method === 'patch')
		request.headers.setContentType('application/merge-patch+json');
	return request;
});

httpClient.interceptors.response.use((response) => {
	if (response.status >= HttpStatusCode.BadRequest)
		throw new Error(`${response.data.insideExceptionCode || response.status}`);
	return response;
});

export default httpClient;
