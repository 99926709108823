import React, { useRef, useState } from 'react';
import {
	Row,
	Col,
	CardBody,
	Card,
	Alert,
	Container,
	Form,
	Label,
	Input,
	CardTitle,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useForm } from '@mantine/form';
import { Link } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { LoadingOverlay } from '@mantine/core';
import { omit } from 'lodash';
import { ErrorCodes, mapCodesToMessage } from 'types/ErrorCodes';
import logo from 'assets/images/logoMainDark.svg';

export interface LoginValues {
	email: string;
	password: string;
	smsCode: string;
	remember?: boolean;
}

const initialValues: LoginValues = {
	email: '',
	password: '',
	smsCode: '',
	remember: true,
};

export const Login: React.FC = () => {
	const form = useForm({
		initialValues,
	});

	const [codeModalVisible, setCodeModalVisible] = useState(false);

	const [formLoading, setFormLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);

	const { login, requestSMS } = useAuth();

	const [codeSuccessMessage, setCodeSuccessMessage] = useState('');
	const [codeErrorMessage, setCodeErrorMessage] = useState('');
	const [disableModalAnim, setDisableModalAnim] = useState(false);

	const smsCodeInput = useRef<HTMLInputElement>(null);

	const handleSubmit = async () => {
		setDisableModalAnim(false);
		setFormLoading(true);

		const isSuccess = await requestSMS(omit(form.values, 'remember'));

		setFormLoading(false);
		if (!isSuccess) {
			form.setErrors({
				auth: 'Podane dane są nieprawidłowe. Sprawdź poprawność wprowadzonych danych',
			});
			return;
		}
		form.clearErrors();

		showCodeModal();
	};

	const showCodeModal = () => {
		setCodeModalVisible(true);
		setTimeout(() => {
			smsCodeInput.current?.focus();
		}, 100);
	};

	const handleCodeSubmit = async () => {
		setDisableModalAnim(true);
		setModalLoading(true);
		const result = await login(form.values);
		setModalLoading(false);
		form.setFieldValue('smsCode', '');

		if (result === 1) {
			form.reset();
			setCodeModalVisible(false);
			setCodeSuccessMessage('Zweryfikowano kod!');
		} else {
			if (
				[ErrorCodes.EXPIRED_SMS_CODE, ErrorCodes.USED_SMS_CODE].includes(result)
			)
				setCodeModalVisible(false);
			setCodeErrorMessage(mapCodesToMessage(result));
			setTimeout(() => {
				setCodeErrorMessage('');
			}, 1500);
		}
	};

	return (
		<div className="account-pages">
			<Container className="position-absolute">
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden mb-0">
							<LoadingOverlay visible={formLoading} />
							<div className="logo-bar">
								<div>
									<img
										src={logo}
										alt="company logo"
										className="img-fluid header-logo"
									/>
								</div>
							</div>
							<CardBody>
								<div className="p-2 pt-0">
									<CardTitle className="mb-3">Zaloguj się do panelu</CardTitle>
									<Form
										className="form-horizontal"
										onSubmit={form.onSubmit(handleSubmit)}
									>
										{form.errors.auth && (
											<Alert color="danger">{form.errors.auth}</Alert>
										)}
										<div className="mb-3">
											<Label className="form-label">Email</Label>
											<Input
												required
												type="email"
												className="form-control"
												placeholder="Wpisz adres email"
												{...form.getInputProps('email')}
												invalid={!!form.errors.auth}
											/>
										</div>
										<div className="mb-3">
											<Label className="form-label">Hasło</Label>
											<Input
												required
												type="password"
												className="form-control"
												placeholder="Wpisz hasło"
												{...form.getInputProps('password')}
												invalid={!!form.errors.auth}
											/>
										</div>
										<div className="form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="customControlInline"
												{...form.getInputProps('remember', {
													type: 'checkbox',
												})}
											/>
											<label
												className="form-check-label"
												htmlFor="customControlInline"
											>
												Zapamiętaj mnie
											</label>
										</div>
										<div className="mt-3 d-grid">
											<button
												className="btn btn-primary btn-block"
												type="submit"
											>
												Zaloguj się
											</button>
										</div>
										<div className="mt-3 text-center">
											<Link to="/reset-password" className="text-muted">
												<i className="mdi mdi-lock me-1" />
												Nie pamiętam hasła
											</Link>
										</div>
										{
											//@ts-ignore
											<SweetAlert
												show={codeModalVisible}
												title="Kod uwierzytelniający"
												reverseButtons
												onConfirm={handleCodeSubmit}
												showCancel={!modalLoading}
												disabled={modalLoading}
												closeOnClickOutside={false}
												cancelBtnBsStyle="danger"
												cancelBtnText="Anuluj"
												confirmBtnBsStyle="success"
												confirmBtnText="Zatwierdź"
												openAnim={
													disableModalAnim
														? false
														: { name: 'modalSlideIn', duration: 300 }
												}
												onCancel={() => {
													setCodeModalVisible(false);
													form.reset();
												}}
											>
												<div className="my-2">
													<LoadingOverlay visible={modalLoading} />
													<p className="card-title-desc">
														Na Twój nr telefonu wysłaliśmy kod. Wpisz kod w
														poniższe pole. Twój kod jest ważny 5 minut.
													</p>
													<Input
														innerRef={smsCodeInput}
														required
														className="form-control"
														placeholder="Podaj kod uwierzytelniający"
														{...form.getInputProps('smsCode')}
														onKeyPress={({ key }) =>
															key === 'Enter' ? handleCodeSubmit() : null
														}
														onSubmit={handleCodeSubmit}
													/>
												</div>
											</SweetAlert>
										}
										<SweetAlert
											success
											show={!!codeSuccessMessage}
											openAnim={false}
											showConfirm={false}
											title={codeSuccessMessage}
											onConfirm={() => {}}
										/>
										<SweetAlert
											error
											show={!!codeErrorMessage}
											showConfirm={false}
											openAnim={false}
											title={codeErrorMessage}
											onConfirm={() => {}}
										/>
									</Form>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
