import moment from 'moment';
import { Admin, Client, Publisher } from 'types/user';

/**
 *
 * @param password one capital one number one samll speciall and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
		password
	);
}

export function validateEmail(mail: string) {
	if (!mail) return true;
	return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

export function randomDate(start: Date, end: Date) {
	return moment(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	).toDate();
}

export function formatDateForInput(date: moment.MomentInput) {
	return moment(date).format('YYYY-MM-DD');
}

export function formatDateForPresentation(
	date: moment.MomentInput | string = new Date()
) {
	return moment(date).format('DD/MM/YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput | string = new Date()
) {
	return moment(date).format('HH:mm');
}

export function formatExactDate(
	date: moment.MomentInput | string = new Date()
) {
	return moment(date).format('YYYY.MM.DD HH:mm:ss');
}

export function sortByDate(
	dateA: string | Date,
	dateB: string | Date,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function sortByName(
	nameA: string,
	nameB: string,
	order: 'asc' | 'desc' = 'asc'
) {
	return order === 'asc'
		? nameA.localeCompare(nameB)
		: nameB.localeCompare(nameA);
}

export function incrementDate(date: Date | string) {
	return moment(date).add(1, 'day').toDate();
}

export function priceToFixed(price: number) {
	return price.toFixed(Number.isInteger(price) ? 0 : 2);
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export function formatPrice(price?: number) {
	if (price === undefined) return '-';
	return price.toFixed(Number.isInteger(price) ? 0 : 2);
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

const URL_REGEX =
	/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

export function validateUrl(url?: string) {
	if (!url) return false;

	const urlRegex = new RegExp(URL_REGEX);
	return urlRegex.test(url);
}

const PX_TO_MM_RATIO = 0.2645833333;

export function pxToMM(px: number) {
	return px * PX_TO_MM_RATIO;
}

export function roleNameForInsideId(insideId: string) {
	if (insideId[0] === 'P') return 'Pobierający';
	if (insideId[0] === 'Z') return 'Zamieszczający';
	return 'Menadżer';
}

export function isAdmin(user: Admin | Client | Publisher): user is Admin {
	return !!(user as Admin).group;
}

export function getDownloadName(char: string) {
	switch (char) {
		case 'U':
			return 'Pobranie uproszczone';
		case 'M':
			return 'Pobranie medialne';
		case 'K':
			return 'Pobranie komercyjne';
		case 'C':
			return 'Pobranie komercyjne';

		default:
			return 'Pobranie uproszczone';
	}
}
