import React from 'react';
import { Login } from 'pages/unauthenticated/Login';
import { ResetPassword } from 'pages/unauthenticated/ResetPassword';
import { SetNewPassword } from 'pages/unauthenticated/SetNewPassword';
import { Routes, Route, Navigate } from 'react-router-dom';

export const UnauthenticatedApp: React.FC = () => {
	return (
		<Routes>
			<Route path="login" element={<Login />} />
			<Route path="reset-password" element={<ResetPassword />} />
			<Route path="set-password/:token" element={<SetNewPassword />} />
			<Route path="*" element={<Navigate to="/login" />} />
		</Routes>
	);
};
