import React, { useContext, ReactNode, useLayoutEffect, useRef } from 'react';
import { LoginValues } from 'pages/unauthenticated/Login';
import { useLocalStorage } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { Admin, User } from 'types/user';
import UnauthenticatedApi from 'api/UnauthenticatedApi';
import httpClient from 'api/clients/httpClient';
import Cookies from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';

interface TokenPayload extends JwtPayload {
	accountType: string;
	userId: string;
}

interface Context {
	user: User | null;
	changeUser: (newUser?: User | null) => void;
	login: (values: LoginValues) => Promise<number>;
	logout: () => void;
	requestSMS: (
		values: Omit<LoginValues, 'remember' & 'code'>
	) => Promise<boolean>;
}

const AuthContext = React.createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const [user, setUser, clearUser] = useLocalStorage<User | null>({
		key: 'user',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const refreshToken = useRef(Cookies.get('refresh_token'));

	useLayoutEffect(() => {
		getTokenWithResfreshToken();
		// eslint-disable-next-line
	}, []);

	const getTokenWithResfreshToken = async () => {
		if (!refreshToken.current) {
			clearUser();
			return;
		}

		try {
			const response = await UnauthenticatedApi.refreshToken(
				refreshToken.current
			);

			const { token, refresh_token } = response.data;

			const decoded = jwtDecode(token) as TokenPayload;

			const remember = !!Cookies.get('refresh_token');

			setAuth(decoded, token, refresh_token, remember);

			const user = await getUser(decoded);

			setUser(user);
		} catch (error: any) {
			console.error(error);
			if (error.message !== 'Request aborted') clearUser();
		}
	};

	const refreshTokenWhenExpire = (token: TokenPayload) => {
		const tokenLifeSpan = token.iat && token.exp ? token.exp - token.iat : 0;
		// Refresh 5 sec before token expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		setTimeout(() => {
			getTokenWithResfreshToken();
		}, refreshTimeout);
	};

	const requestSMS = async ({
		email,
		password,
	}: Omit<LoginValues, 'remember' & 'code'>) => {
		try {
			const response = await UnauthenticatedApi.requestSMSCode({
				email,
				password,
			});

			const { verification } = response.data;

			return verification;
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	const login = async ({ email, password, smsCode, remember }: LoginValues) => {
		try {
			const response = await UnauthenticatedApi.login({
				email,
				password,
				smsCode,
			});

			if (response.status !== 200)
				return response.data.insideExceptionCode || 0;

			const { token, refresh_token } = response.data;

			const decoded = jwtDecode(token) as TokenPayload;

			const isAdmin = decoded.accountType === 'admin';
			if (!isAdmin) throw new Error('Not an admin');

			setAuth(decoded, token, refresh_token, remember);

			const user = await getUser(decoded);

			setTimeout(() => {
				setUser(user);
			}, 1100);

			return 1;
		} catch (error) {
			console.error(error);
			return 0;
		}
	};

	const logout = () => {
		queryClient.cancelQueries();
		Cookies.remove('refresh_token');
		httpClient.defaults.headers.common['Authorization'] = '';
		refreshToken.current = undefined;
		clearUser();
		queryClient.clear();
	};

	const saveRefreshToken = (token: string) => {
		refreshToken.current = token;
		Cookies.set('refresh_token', token, { secure: true, expires: 360 });
	};

	const setAuth = (
		decoded: TokenPayload,
		token: string,
		refresh: string,
		remember = true
	) => {
		refreshTokenWhenExpire(decoded);

		setAuthHeaders(token);

		if (remember) saveRefreshToken(refresh);
		else refreshToken.current = refresh;
	};

	const changeUser = (newUser: User | null = null) => setUser(newUser);

	return (
		<AuthContext.Provider
			value={{ user, changeUser, login, logout, requestSMS }}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeaders(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

async function getUser(decodedToken: TokenPayload) {
	return (await httpClient.get<Admin>(`admins/${decodedToken.userId}`)).data;
}
